import React, {useState} from 'react';
import ReactLogo from './../assets/images/logo.svg';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
  } from 'reactstrap';


const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return (
        <>
      <Navbar className={"mx-0 firstGrey border"} fixed="top" light expand="md">
        <NavbarBrand href="http://inprocom.es/"><img className="img-fluid" src={ReactLogo} alt="Inprocom Logo" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="me-auto" navbar>
            <NavItem>
              <NavLink href="/company"><strong>Empresa</strong></NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/services"><strong>Servicios</strong></NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/projects"><strong>Proyectos</strong></NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/contact"><strong>Contacto</strong></NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </>
    )
}

export default Header;