import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faUser } from '@fortawesome/free-solid-svg-icons';

const Team = (props) => {
    return (
        <div className='row m-0 p-0'>
            <div className='col-2'>
            </div>
            <div className='col-8 team secondColor'>
                <h1 className="display-3 card__title">Equipo profesional</h1>
                <div className="text-left">
                    <p className="card__text p-2 text-left"><FontAwesomeIcon icon={faUser} mask={faCircle} size='1x' color='white' transform='shrink-6' /> <strong>Javier Paricio Caño</strong> ▪ Ingeniero de Caminos, Canales y Puertos.
                Profesor en la Universidad Miguel Hernández en el Área de Medios Continuos y Teoría de Estructuras.
                </p>
                    <p className="card__text p-2 text-left"><FontAwesomeIcon icon={faUser} mask={faCircle} size='1x' color='white' transform='shrink-6' /> <strong>Manuel Paricio Caño</strong> ▪ Ingeniero Industrial.
                </p>
                </div>
                <h1 className="display-3 card__title">Personal colaborador</h1>
                <div className="text-left">
                    <p className="card__text p-2 text-left"><FontAwesomeIcon icon={faUser} mask={faCircle} size='1x' color='white' transform='shrink-6' /> <strong>Antonio Aracil Pérez</strong> ▪ Arquitecto. Master.
                </p>
                    <p className="card__text p-2 text-left"><FontAwesomeIcon icon={faUser} mask={faCircle} size='1x' color='white' transform='shrink-6' /> Jose <strong>María Flores Moreno</strong> ▪ Arquitecto.
                </p>
                    <p className="card__text p-2 text-left"><FontAwesomeIcon icon={faUser} mask={faCircle} size='1x' color='white' transform='shrink-6' /> <strong>César Sala Galvañ</strong> ▪ Ingeniero Industrial.
                </p>
                    <p className="card__text p-2 text-left"><FontAwesomeIcon icon={faUser} mask={faCircle} size='1x' color='white' transform='shrink-6' /> <strong>Pablo Escolano Carrillo</strong> ▪ Ingeniero Técnico de Telecomunicaciones.
                </p>
                </div>
            </div>
            <div className='col-2'>
            </div>
        </div>


    )
}

export default Team;