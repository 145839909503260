import React from 'react';
import CardParallax from '../components/CardParallax';
import Team from '../components/Team';


const MainContainer = () => {
    return (
        <>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <CardParallax title="Su socio de ingeniería" subtitle="Perfil corporativo" text="INPROCOM es una empresa de ingeniería cuya actividad principal es proyectar y dirigir la ejecución de obras e instalaciones, relacionadas con los sectores industrial y edificación. El equipo humano de INPROCOM está formado por un grupo de profesionales cualificados en los campos de la Ingeniería Industrial, Ingeniería de Telecomunicaciones, Ingeniería de Caminos y Arquitectura."
                    image="paralsec1" />
                <CardParallax title="Profesionales y responsables" subtitle="Nuestra filosofía" text="En INPROCOM ofrecemos soluciones que maximizan la durabilidad de la obra y su funcionalidad a la vez que minimizan los recursos necesarios. Esto es posible gracias a que nuestro equipo multidisciplinar valora todas las posibilidades y puntos de vista a la hora de analizar cada situación y así ofrecer la mejor solución e innovar en la propuesta."
                    image="paralsec2" />
                <CardParallax title="Planificación y supervisión de proyectos complejos" image="paralsec3">
                    <Team />
                </ CardParallax>
        </>
    )
}

export default MainContainer;