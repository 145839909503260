const CardParallax = (props) =>  {
    return (
        <div className={`jumbotron jumbotron-fluid paral ${props.image}`}>
        <h1 className="display-3 card__title">{props.title}</h1>
        {props.subtitle && <h2 className="card__subtitle">{props.subtitle}</h2>}
        {props.text && <p className="lead card__text p-4">{props.text}</p>}
        {props.children}
    </div>
    )
}

export default CardParallax;