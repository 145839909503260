const Map = () => {

    
const iframeMap = 

function iframeMap() {
    const dataMap = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3128.581709232947!2d-0.4867078489223974!3d38.35866247955761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd623700960995cf%3A0xd8dee5c52ba18a94!2sCalle%20Hermanos%20Soto%20Ch%C3%A1puli%2C%203%2C%2003010%20Alicante%20(Alacant)%2C%20Alicante!5e0!3m2!1ses!2ses!4v1615722093372!5m2!1ses!2ses" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>';
    return {
        __html: dataMap
    }

}

    return (
       
        <div className='row m-0 p-0'>
            <div className='col-12 text-center m-0 p-0'>
            <div dangerouslySetInnerHTML={ iframeMap() } />
            </div>
        </div>
    )
}

export default Map;