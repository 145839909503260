import LogoCE from './../assets/images/ce-logo.svg';
import LogoISO from './../assets/images/iso-9001-logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faMapMarkerAlt, faMobile, faArrowAltCircleUp, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import { faInstagram} from '@fortawesome/free-brands-svg-icons';
import React from 'react';

const Footer = () => {
  return (
        <>
      <div className="col-12 paral paralsec4">
        <div className="row text-center">
          <div className="col-12 footer__text">
          <ul className="list-unstyled p-3">
            <li><strong>Inprocom</strong> | Ingeniería de Proyectos y Consultoría en Comunicación, S.L.P.</li><li>Hermanos Soto Chápuli, nº3, Entlo izquierda</li>
            <li>ES - 03010 Alicante</li>
            <li ><a className="text-white" href="tel:609656179">Tel: 609656179 <FontAwesomeIcon icon={faMobile} mask={faCircle} size='1x' color='white' transform='shrink-6' /></a></li>
            <li><a className="text-white" href="mailto:javier.paricio@inprocom.es">javier.paricio@inprocom.es</a></li>
            <li><a className="text-white" href="www.inprocom.es">www.inprocom.es</a></li></ul>
            </div>
         </div> 
        <div className="row">
          <div className="col-12 text-center">
          <span className=""><img className="p-2" src={LogoCE} alt="CE Logo"></img><img className="p-2" src={LogoISO} alt="ISO Logo"></img>
         </span>
            </div>
         </div> 
        <div className="row">
          <div className="col-12 text-center">
          <a className="text-white" href="/contact"><FontAwesomeIcon icon={faMapMarkerAlt} mask={faCircle} size='3x' color='white' transform='shrink-6' /></a>
          <a className="text-white" href="/"><FontAwesomeIcon icon={faArrowAltCircleUp} mask={faCircle} size='3x' color='white' transform='shrink-6' /></a>
          <a className="text-white" href="mailto:javier.paricio@inprocom.es"><FontAwesomeIcon icon={faEnvelope} mask={faCircle} size='3x' color='white' transform='shrink-6' /></a> 
          <a className="text-white" href="tel:609656179"><FontAwesomeIcon icon={faMobile} mask={faCircle} size='3x' color='white' transform='shrink-6' /></a> 
          <a className="text-white" href="https://www.instagram.com/javier.paricio/?hl=es" target="_blank"><FontAwesomeIcon icon={faInstagram} mask={faCircle} size='3x' color='white' transform='shrink-6' /></a> 
            </div>
         </div> 
        
      
        </div>
    </>
    )
}

export default Footer;