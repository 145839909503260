import React from 'react';
import CardParallax from '../components/CardParallax';


const ServicesContainer = () => {
    return (
        <>

            <div className="Home">
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <CardParallax title="Servicios" subtitle="" text="Nuestra Ingeniería está capacitada para todo tipo de proyectos de Estructuras e Instalaciones en el campo de la obra civil y edificación. También somos especialistas en la rehabilitación de estructuras y consultoría. Integramos todas nuestras capacidades asegurando la máxima eficiencia. Buscamos el equilibrio entre firmeza, funcionalidad y estética. Diseño óptimo con soluciones de vanguardia que combinan la máxima resistencia estructural y una gran sensibilidad a las formas."
                    image="paralsec1">
                    <div className="row m-4">
                        <div className="col-12 card__text">
                            PROYECTOS Y CÁLCULOS ESTRUCTURALES – ASISTENCIA TÉCNICA <br></br>

                            • Cálculo de Estructuras: diseño, dimensionamiento, delineación, memorias y mediciones <br></br>
                            • Proyectos de Ejecución de edificaciones industriales y comerciales <br></br>
                            • Redacción del Anexo de Acometidas para incorporar en proyecto de arquitectura <br></br>
                            • Redacción de la Certificación de Eficiencia Energética en Edificaciones <br></br>
                    </div>
                    </div>
                    <div className="row m-4">
                        <div className="col-12 card__text">
                            DISEÑO INSTALACIONES Y DIRECCIONES DE OBRA<br></br>
                            • Proyecto de Ejecución y Dirección de Obra de las siguientes instalaciones:<br></br>
                            • Infraestructura Común de Telecomunicaciones<br></br>
                            • Instalación para la generación de ACS mediante aerotermia/placas solares<br></br>
                            • Climatización<br></br>
                            • Electricidad<br></br>
                            • Gas Natural<br></br>
                            • Suministro de agua (según CTE HS4)<br></br>
                            • Evacuación de aguas (Residuales y Pluviales, según CTE HS5)<br></br>
                            • Ventilación en viviendas  (según CTE HS3)<br></br>
                            • Protección contra incendios<br></br>
                            • Piscinas de uso privado y colectivo<br></br>

                            • Proyectos de Centros de Transformación y Líneas Eléctricas
                    </div>
                    </div>
                    <div className="row m-4">
                        <div className="col-12 card__text">
                            REHABILITACIÓN<br></br>
                            Al contar con un equipo multidisciplinar se pueden acometer los proyectos de rehabilitación de edificaciones residenciales, administrativas, industriales, deportivas, comerciales… Asimismo tenemos las titulaciones necesarias para, aparte de redactar el proyecto, poder dirigir la ejecución de las obras.
                    </div>
                    </div>
                    <div className="row m-4">
                        <div className="col-12 card__text">
                            INTERVENCIÓN TÉCNICA<br></br>
                            También contamos con amplia experiencia en dictámenes periciales y asistencia a juicio, en calidad de perito, sobre patologías en la edificación.
                    </div>
                    </div>

                </CardParallax>
            </div>

        </>
    )
}

export default ServicesContainer;