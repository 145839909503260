import React from 'react';
import CardParallax from '../components/CardParallax';
import Project from '../components/Project';
import DocumentContainer from './DocumentContainer';


const ProjectsContainer = () => {
    return (
        <>
            <div className="Home">
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                {/* -------------------------------------------------- */}
                {/* Obra nueva */}
                {/* -------------------------------------------------- */}
                <CardParallax title="Obra Nueva" subtitle=""
                    image="paralsec1">
                        <DocumentContainer text="Obra nueva" urldoc="https://drive.google.com/file/d/1YHLeJjQKdDRg2_2qwU_EbzEKiVd617y0/view?usp=sharing"></DocumentContainer>
                    <Project title="2015 Edificación residencial" text="Cálculo Estructural de 64 viviendas con cimentación profunda mediante pilotes prefabricados" projectFolder="et_javea" engineerstructure="Javier Paricio Caño" architecture="Borja Arquitectos, S.L.P." promoter="Facornata Servicios y Gestiones, S.L." />
                    <Project title="2017 Edificación residencial" text="Cálculo Estructural de 240 viviendas con cimentación profunda mediante pilotes prefabricados" projectFolder="et_viride" engineerstructure="Javier Paricio Caño" architecture="Borja Arquitectos, S.L.P." promoter="Cooperativa Valenciana, S.L." />
                    <Project title="2017 Edificación residencial" text="Cálculo Estructural de 11 viviendas con cimentación superficial mediante zapatas" projectFolder="et_costablanca" engineerstructure="Javier Paricio Caño" architecture="Borja Arquitectos, S.L.P." promoter="Promociones Inmobiliarias Espacio, S.L." />
                    <Project title="2004 Edificación industrial" text="Proyecto de Ejecución de Nave industrial prefabricada de hormigón" projectFolder="nave_edafologia" engineer="Manuel Paricio Caño" engineerstructure="Javier Paricio Caño" architecture="" promoter="Universidad Miguel Hernández" />
                    <Project title="2004 Edificación industrial" text="Proyecto de Ejecución de Nave industrial con estructura metálica" projectFolder="nave_motores" engineer="Manuel Paricio Caño" engineerstructure="Javier Paricio Caño" architecture="" promoter="Universidad Miguel Hernández" />
                    <Project title="2008 Edificación Comercial" text="Proyecto de Ejecución de edificio comercial con dos planta bajo rasante y cimentación mediante losa" projectFolder="et_alicantina" engineer="Manuel Paricio Caño" engineerstructure="Javier Paricio Caño" architecture="" promoter="Metalúrgica Alicantina de Vallas, S.L." />
                    <Project title="2011 Edificación residencial" text="Cálculo Estructural de vivienda unifamiliar con estructura metálica, forjado de chapa colaborante y cimentación mediante pozos" projectFolder="et_orito" engineer="" engineerstructure="Javier Paricio Caño" architecture="Aracil & Flores Arquitectos S.L.P." promoter="Luis Pastor Planelles" />
                    <Project title="2012 Edificación residencial" text="Cálculo Estructural de vivienda unifamiliar industrializada con estructura metálica, forjado de chapa colaborante y cimentación superficial mediante zapatas" projectFolder="et_girona" engineer="" engineerstructure="Javier Paricio Caño" architecture="Aracil & Flores Arquitectos S.L.P." promoter="Elisabet Holgado" />
                    <Project title="2012 Edificación residencial" text="Cálculo Estructural de vivienda unifamiliar CON estructura metálica, forjado de chapa colaborante y cimentación superficial mediante zapatas" projectFolder="et_biar" engineer="" engineerstructure="Javier Paricio Caño" architecture="Aracil & Flores Arquitectos S.L.P." promoter="Miguel Alcaraz" />
                </ CardParallax>
                {/* -------------------------------------------------- */}
                {/* Rehabilitación */}
                {/* -------------------------------------------------- */}
                <CardParallax title="Rehabilitación" subtitle="" text="Analizamos estructuras para su rehabilitación y reforma, así como para cumplir con las más actuales regulaciones de construcción. Somos un equipo de ingenieros experimentados que puede trabajar como una extensión de su equipo interno proporcionando un gran valor añadido. Hemos trabajado con numerosos clientes en proyectos de refuerzo estructural ayudándoles en la rehabilitación de edificios y estructuras."
                    image="paralsec1">
                    <DocumentContainer text="Rehabilitación" urldoc="https://drive.google.com/file/d/1jJsnOKiA9LdiLGOpVcAhIGvDZhBaokFv/view?usp=sharing"></DocumentContainer>
                    <Project title="2005-2018 Edificación residencial" text="Rehabilitación de estructura metálica en planta baja en edificio de 252 viviendas en 22 plantas en C.P. Edificio Leo, Alicante" projectFolder="reh_et_met_leo" engineer="Javier Paricio Caño" architecture="" promoter="C.P. Edificio Leo" />
                    <Project title="2008 Edificación residencial" text="Refuerzo estructural y reparación de fachadas del edificio “Texas” de 16 plantas en Alicante" projectFolder="reh_ed_texas" engineerstructure="Javier Paricio Caño" architecture="Aracil & Flores Arquitectos S.L.P." promoter="C.P. ED. TEXAS" />
                    <Project title="2013 Edificación residencial" text="Proyecto de Reparación de deficiencias en fachada residencial “Natura Playa II” para 180 viviendas, El Campello" projectFolder="reh_ed_natura_2" engineerstructure="Javier Paricio Caño" architecture="Aracil & Flores Arquitectos S.L.P." promoter="Promociones y Propiedades Espacio, S.L." />
                </ CardParallax>
                {/* -------------------------------------------------- */}
                {/* Instalaciones */}
                {/* -------------------------------------------------- */}
                <CardParallax title="Instalaciones" subtitle="" text=""
                    image="paralsec1">
                    <DocumentContainer text="Intervención técnica" urldoc="https://drive.google.com/file/d/1WXRXmpfnrXkI39RqPRPW0BJU5n3GQT0A/view?usp=sharing"></DocumentContainer>
                    <Project title="2005 Edificación residencial" text="Proyecto y Dirección Obra de piscina olímpica de 1.200 m3 en C.P. Edificio Leo, Alicante" projectFolder="inst_piscina_leo" engineer="Javier Paricio Caño" promoter="C.P. Edificio Leo" />
                    <Project title="2017 Edificación residencial" text="Instalaciones para el conjunto residencial de 126 Viviendas, Hacienda del Mar 2, Alicante" projectFolder="inst_hm2" engineerinstallation="Inprocom" architecture="Borja Arquitectos, S.L.P." promoter="Promociones Inmobiliarias Espacio, S.L." />
                    <Project title="2017 Edificación residencial" text="Cálculo Estructural de 240 viviendas con cimentación profunda mediante pilotes prefabricados" projectFolder="inst_viride" engineerstructure="Javier Paricio Caño" architecture="Borja Arquitectos, S.L.P." promoter="Cooperativa Valenciana, S.L." />
                    <Project title="2013 Edificación residencial" text="Instalaciones para el conjunto residencial de 108 Viviendas, Hacienda de Vistahermosa, Alicante" projectFolder="inst_vistah" engineerinstallation="Inprocom" architecture="Borja Arquitectos, S.L.P." promoter="Espacio Arpada Desarrollos, S.L." />
                    <Project title="2016 Edificación residencial" text="Instalaciones para el conjunto residencial de 70 Viviendas y locales comerciales, La Mallaeta, Villajoyosa" projectFolder="inst_villajoyosa" engineerinstallation="Inprocom" architecture="Borja Arquitectos, S.L.P." promoter="Espacio Mallaeta, S.L.U." />
                </CardParallax>
                {/* -------------------------------------------------- */}
                {/* Intervención técnica */}
                {/* -------------------------------------------------- */}
                <CardParallax title="Intervención Técnica" subtitle="" text="Realizamos análisis de estructuras utilizando herramientas de software estándar de la industria. De este modo, simplificamos y resolvemos incluso los problemas de diseño estructural más complejos, como la capacidad, la robustez, la durabilidad, la resistencia y las cuestiones de sostenibilidad, en plazo y precio razonables."
                    image="paralsec1">
                    <DocumentContainer text="Intervención técnica" urldoc="https://drive.google.com/file/d/11aNDdLHX4Yf4OQOCivhYevMPgmarDE5O/view?usp=sharing"></DocumentContainer>
                </CardParallax>
            </div>
        </>
    )
}

export default ProjectsContainer;