import './App.scss';
import React from 'react';
import Header from './containers/Header';
import Footer from './containers/Footer';
import CompanyContainer from './containers/CompanyContainer';
import ContactContainer from './containers/ContactContainer';
import ServicesContainer from './containers/ServicesContainer';
import ProjectsContainer from './containers/ProjectsContainer';


const Route = ({ path, component }) => {
  const pathname = window.location.pathname;
  if (pathname.includes(path) || pathname === '/') {
  return (
    React.createElement(component)
  );
  } else {
  return null;
  }
  };

function App() {
  return (
    <>
       <Header />
        <Route path='/company' component={Company} />
        <Route path='/services' component={Services} />
        <Route path='/projects' component={Projects} />
        <Route path='/contact' component={Contact} />
        <Footer />
    </>
  );
}

const Company = () => (
  <CompanyContainer />
);
const Services = () => (
  <ServicesContainer />
);
const Projects = () => (
  <ProjectsContainer />
);
const Contact = () => (
  <ContactContainer />
);

export default App;
