import React, { useState } from 'react';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption
} from 'reactstrap';
import { Card, CardTitle, CardText } from 'reactstrap';

const Project = (props) => {

    let items = [
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image01.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image02.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image04.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image05.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image06.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image07.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image08.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image09.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image10.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image11.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image12.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image13.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image14.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },
        {
            src: (() => {
                try {
                    return require(`./../../src/assets/projects/${props.projectFolder}/image15.jpg`).default;
                }
                catch (error) {

                    return null;
                }
            })()
            ,
            // altText: 'Slide 3',
            // caption: 'Slide 3'
        },

    ];


    items = items.filter(e => e.src !== null);

    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    }

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    }

    const goToIndex = (newIndex) => {
        if (animating) return;
        setActiveIndex(newIndex);
    }

    const slides = items.map((item) => {
        if (item != null)
            return (
                <CarouselItem className='text-center'
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                    key={item.src}
                >
                    <img className="rounded img-fluid" src={item.src} alt={item.altText} />
                    <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
                </CarouselItem>
            );
    });
    return (
        <div className='row m-4'>
            <div className="col-sm-1 col-lg-2 d-none d-sm-block">
            </div>
            <div className="col-xs-12 col-sm-10 col-lg-8">
                <Card className='p-4 thirdColor' body inverse>
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                    >
                        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                        {slides}
                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                    </Carousel>
                    <CardTitle className='mt-4 project__title text-center' tag="h5">{props.title}</CardTitle>
                    <CardText className='project__text'>{props.text}</CardText>
                    <div class="row">
                        <div class="col-12  border">
                            {props.engineer && <div className='project__text'><strong>Ingeniero </strong>{props.engineer}</div>}
                            {props.engineerinstallation && <div className='project__text'><strong>Ingeniería instalaciones </strong>{props.engineerinstallation}</div>}
                            {props.engineerstructure &&<div className='project__text'><strong>Ingeniero del cálculo estructural </strong>{props.engineerstructure}</div>}
                            {props.architecture && <div className='project__text'><strong>Arquitectura </strong>{props.architecture}</div>}
                            {props.promoter && <div className='project__text'><strong>Promotor </strong>{props.promoter}</div>}
                        </div>
                    </div>
                </Card>
            </div>
            <div className="col-sm-1 col-lg-2 d-none d-sm-block">
            </div>

        </div>
    );
}

export default Project;