import React from 'react';
import Map from '../components/Map';


const ContactContainer = () => {
    return (
        <>
            <div className="Home">
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <div className="row m-4"></div>
                <Map />
            </div>
           
        </>
    )
}

export default ContactContainer;