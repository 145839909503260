import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFilePdf } from '@fortawesome/free-solid-svg-icons';



const DocumentContainer = (props) => {
    return (
        <>
            <div className="Document">
                <div className="row m-4">
                    <div className="col-12 text-center">
                        <a href={props.urldoc} target="_blank"><FontAwesomeIcon icon={faFilePdf} mask={faCircle} size='3x' color='white' transform='shrink-6' /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentContainer;